import { defineChain } from "viem";
import { chainConfig } from "viem/op-stack";

export const CHAIN_ID =
  process.env.NEXT_PUBLIC_ENVIRONMENT === "development" ? 84532 : 8453;
export const customChain = defineChain({
  id: CHAIN_ID,
  network: "base-Mainnet",
  name: "Base Mainnet",
  nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
  rpcUrls: {
    default: {
      http: [
        "https://sly-soft-hexagon.base-mainnet.quiknode.pro/c78f25e1128284ba916ff12a97cd589702f59646/",
      ],
    },
  },
  blockExplorers: {
    default: {
      name: "Basescan",
      url: "https://basescan.org",
      apiUrl: "https://api.basescan.org/api",
    },
  },
  contracts: {
    ...chainConfig.contracts,
  },
});
