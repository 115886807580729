"use client";
import { useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";
import { useAppSelector } from "@/redux/hooks";

type UseSocket = {
  socket: Socket | null;
};

export interface SocketOptionsType {
  reconnection: boolean;
  reconnectionDelay: number;
  reconnectionDelayMax: number;
  reconnectionAttempts: number;
  transports: string[];
  auth?: {
    token: string;
  };
}

interface useSocketParameterType {
  SOCKET_OPTIONS: SocketOptionsType;
  TOKEN: string;
  SET_SOCKET_CONTEXT: (socket: Socket) => void;
}

export const socketOptions: SocketOptionsType = {
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 1000,
  reconnectionAttempts: 100,
  transports: ["websocket", "polling"],
};

const useSocket = () => {
  const TOKEN = useAppSelector((state) => state?.Auth?.token);
  const [socket, setSocket] = useState<Socket | null>(null);

  const Initializer = () => {
    try {
      socketOptions.auth = {
        token: `Bearer ${TOKEN}`,
      };
      const socketURl = process.env.NEXT_PUBLIC_SOCKET_URL!;
      const instance = io(socketURl, socketOptions);
      return instance;
    } catch (error) {
      console.log(error);
      if (error) throw error;
    }
  };

  useEffect(() => {
    if (TOKEN) {
      const socketInstance = Initializer();
      if (socketInstance) {
        socketInstance.on("connect", async () => {
          console.log("connected");
        });
      }
      socketInstance && setSocket(socketInstance);

      return () => {
        socketInstance?.disconnect();
      };
    }
  }, [TOKEN]);

  return socket;
};

export default useSocket;
