import { CHAIN_ID } from "@/app/config/chain";
import { useAccount, useSwitchChain } from "wagmi";

const useVerifyChain = () => {
  const { chains, switchChainAsync } = useSwitchChain();
  const account = useAccount();
  const chainConfig = {
    chainId: CHAIN_ID,
    addEthereumChainParameter: {
      chainName: "Base Mainnet",
      nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
      rpcUrls: ["https://mainnet.base.org/"],
      blockExplorerUrls: ["https://basescan.org"],
    },
  };
  const verifyChain = async () => {
    const chainId = await account?.connector?.getChainId();
    if (chainId !== CHAIN_ID) {
      try {
        const data = await switchChainAsync(chainConfig);

        // change account chain if exists
        if (account?.connector?.switchChain) {
          await account.connector.switchChain(chainConfig);
        }
        if (data) {
          console.log("chain data", data);
          return true;
        }
        return false;
      } catch (e) {
        console.log("chain Switch Error", e);
        return false;
      }
    } else {
      return true;
    }
  };
  return verifyChain;
};

export default useVerifyChain;
