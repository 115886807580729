import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export default function Button({
  children,
  type,
  onClick,
  className,
  disabled = false,
  attributes = {},
}: {
  children: ReactNode;
  onClick?: (a?: any) => void;
  className?: string;
  attributes?: object;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
}) {
  let updatedClassName = className ? className : "";
  if (disabled) {
    updatedClassName = "cursor-not-allowed bg-gray-300 hover:bg-gray-300";
  }
  return (
    <button
      type={type ?? "button"}
      disabled={disabled}
      onClick={onClick ? onClick : () => null}
      {...attributes}
      className={twMerge(
        "text-white bg-primary-blue hover:bg-blue-600 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none",
        updatedClassName,
      )}
    >
      {children}
    </button>
  );
}
