import { useCallback, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";

export default function useWalletStatus(showNotification: boolean) {
  const { status } = useAccount();
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (status === "disconnected") {
      if (showNotification && count > 0) {
        toast.error("Please Connect Wallet");
      }
    }
    if (showNotification && count > 0) {
      if (status === "reconnecting" || status === "connecting") {
        // toast.info("Connecting to Wallet");
      }
    }
    if (status === "connected") {
      // return tradeOption === "buy" ? handleBuy() : handleSell();
    }
  }, [status, count]);
  const notifyUser = () => setCount((c) => c + 1);

  return { isConnected: status === "connected", status, notifyUser };
}
