import { useReadContract, UseReadContractReturnType } from "wagmi";
import routerABI from "@/modules/SmartContracts/abis/router-abi.json";
import { hexString } from "@/types/global";
import { ethers } from "ethers";
import uniswapV2RouterABI from "@/modules/SmartContracts/abis/uniswap-v2-router.json";
import { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import { useAppSelector } from "@/redux/hooks";

export default function useMinAmountOut(
  trade: "buy" | "sell",
  mint: string,
  onUniswap: boolean,
  amount: number | string,
  slippage: number,
) {
  const [result, setResult] = useState({
    status: "",
    data: "",
    value: "0", // with slippage calculation
  });
  const UNISWAPV2_ROUTER = process.env
    .NEXT_PUBLIC_UNISWAPV2_ROUTER_ADDRESS as hexString;
  const ROUTER_ADDRESS = useAppSelector(
    (state) => state.Config?.config?.BASEFUN_ROUTER,
  ) as hexString;
  const WETH_ADDRESS = process.env.NEXT_PUBLIC_WETH_ADDRESS as hexString;
  const ZERO_ADDRESS = process.env.NEXT_PUBLIC_ZERO_ADDRESS as hexString;
  const abi = onUniswap ? uniswapV2RouterABI : routerABI;
  const address = onUniswap ? UNISWAPV2_ROUTER : ROUTER_ADDRESS;
  const [amountInEther, setAmountInEther] = useState(
    ethers.utils.parseEther("0"),
  );
  useEffect(() => {
    try {
      setAmountInEther(
        ethers.utils.parseEther(
          isNaN(parseInt(amount.toString())) ? "0" : amount.toString(),
        ),
      );
    } catch (e) {
      console.log("Error setting amount", e);
      setAmountInEther(ethers.utils.parseEther("0"));
    }
  }, [amount]);
  let args = [];
  if (onUniswap) {
    args =
      trade === "buy"
        ? [amountInEther, [WETH_ADDRESS, mint]]
        : [amountInEther, [mint, WETH_ADDRESS]];
  } else {
    // available_tokens -> reserves.token - 20 billion
    // when buying
    //  if(minOut> avaiable_token ? avaiable_token : minOut)
    args =
      trade === "buy"
        ? [mint, WETH_ADDRESS, amountInEther]
        : [mint, ZERO_ADDRESS, amountInEther];
  }
  const {
    status,
    data,
    failureReason,
    dataUpdatedAt,
  }: UseReadContractReturnType = useReadContract({
    abi,
    address,
    functionName: "getAmountsOut",
    args: args,
  });
  useEffect(() => {
    if (status === "success") {
      const readData = data as string[] | string;
      // const dataValue = ethers.utils.formatEther(
      const dataValue = onUniswap
        ? readData[1]
        : (readData.toString() as string);
      const dataValue2 = BigNumber(dataValue)
        .multipliedBy(1 - slippage / 100)
        .toFixed(0, BigNumber.ROUND_FLOOR);

      setResult({
        status,
        data: ethers.utils.formatEther(dataValue),
        value: dataValue2,
      });
    }
  }, [status, data, slippage, amountInEther]);

  return {
    status,
    result,
    amount,
    slippage,
    data: result.value,
    reason: failureReason,
  };
}
