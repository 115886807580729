import styles from "./twintabs.module.scss";
import { singleItem } from "@/types/global";
import { twMerge } from "tailwind-merge";

export default function TwinTabs({
  options,
  childrenClassName,
  containerClassName,
  value,
  onChange,
}: {
  options: singleItem[];
  value: string;
  childrenClassName?: string;
  containerClassName?: string;
  onChange: (a: string) => void;
}) {
  return (
    <div
      className={twMerge(
        `${styles.optionsContainer} relative h-[50px] flex items-center bg-[#939FB829]`,
        containerClassName ?? "",
      )}
    >
      <div
        className={`bg-primary-blue ${styles.OptionBg} ${value === options[1].key ? styles.ActiveOptionBg : ""}`}
      />
      {options.map((item: singleItem) => (
        <div
          className={twMerge(
            `cursor-pointer relative w-1/2 py-1 text-center ${styles.eachOption} ${value === item.key ? `${styles.activeOption} ${styles.activeOption} text-white` : "text-primary-dark"}`,
            childrenClassName ?? "",
          )}
          key={item.key}
          onClick={() => onChange(item.key)}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
}
