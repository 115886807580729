"use client";
import React, { useEffect, useRef, useState } from "react";
import styles from "./animation.module.scss";
import Lottie from "react-lottie";
import * as createCoin from "./lottiecoins.json";
import * as fireWorks from "./fireworks.json";

export default function Animations({
  animation = "create_coin",
  sound = false,
  onClose,
}: {
  animation: string;
  onClose?: () => void;
  sound: boolean;
}) {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [play, setPlay] = useState(sound);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation === "create_coin" ? createCoin : fireWorks,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (audioRef && audioRef.current) {
      if (sound) {
        audioRef.current.play();
        timer = setTimeout(() => {
          audioRef?.current?.pause();
        }, 12000);
      } else {
        audioRef.current.pause();
      }
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [sound, audioRef]);
  return (
    <>
      <div
        className={styles.mainCont}
        onClick={() => {
          onClose && onClose();
        }}
      >
        <Lottie options={defaultOptions} height={"100vh"} width={"100vw"} />
      </div>
      <audio src={"/sounds/token_created.mp3"} loop={play} ref={audioRef} />
    </>
    // <Popup show={true} onClose={() => {}}>

    // </Popup>
  );
}
